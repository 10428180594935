<template>

  <div class="list-view product-checkout">

    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <!-- Left Card -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Opzioni di pagamento</b-card-title>
          <b-card-text
            v-show="paymentMethodsLoadStatus === 2
              && paymentMethods.length > 0"
            class="text-muted mt-25"
          >
            Seleziona il metodo di pagamento
          </b-card-text>
        </b-card-header>
        <b-card-body>

          <div v-show="paymentMethodsLoadStatus === 2 && paymentMethods.length === 0">
            <h5>Non hai ancora un metodo di pagamento</h5>

            <label class="mt-1">Intestatario carta</label>
            <input
              id="card-holder-name"
              v-model="name"
              type="text"
              class="form-control mb-2"
            >
            <label class="mt-1">Carta</label>
            <div
              id="card-element"
              class="mt-1"
            />
            <button
              id="add-card-button"
              class="btn btn-primary mt-3"
              @click="submitPaymentMethod()"
            >
              Salva metodo di pagamento
            </button>
          </div>

          <!-- Radios -->
          <b-form-group
            v-show="paymentMethodsLoadStatus === 2
              && paymentMethods.length > 0"
          >
            <b-form-radio
              v-for="method in paymentMethods"
              :key="method.id"
              v-model="paymentMethodSelected"
              :name="'card-'+method.last_four"
              :value="method"
              checked=""
            >
              <p>
                <strong>{{ method.card_holder }} </strong>
                <strong>{{ method.brand }} </strong>
                <label class="text-right">****{{ method.last_four }} SCAD. {{ method.exp_month }}/{{ method.exp_year }}</label>
              </p>
            </b-form-radio>

            <b-form-checkbox
              v-if="paymentMethodSelected !== 'contanti' && paymentMethodSelected !== 'bonifico'"
              v-model="pickUpInSite"
              checked="false"
              name="check-button"
              switch
              inline
            >
              Ritiro in sede
            </b-form-checkbox>

            <hr class="mb-2 mt-1">

            <b-form-radio
              v-model="paymentMethodSelected"
              name="bonifico"
              value="bonifico"
              @input="pickUpInSite = null"
            >
              Bonifico
            </b-form-radio>
            <b-form-radio
              v-model="paymentMethodSelected"
              name="contanti"
              class="mt-1"
              value="contanti"
              @input="pickUpInSite = null"
            >
              Contanti (Pagamento in sede)
            </b-form-radio>
          </b-form-group>
        </b-card-body>
      </b-card>
    </b-overlay>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Riepilogo">

        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Prezzo per {{ getQuantityOfItems }} prodotti
            </div>
            <div class="detail-amt">
              <strong>€{{ totalAmount }}</strong>
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Sconto
            </div>
            <div class="detail-amt">
              <strong>€{{ getDiscountOfPrice }}</strong>
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Spese di spedizione
            </div>
            <div class="detail-amt discount-amt text-success">
              Gratis
            </div>
          </li>
        </ul>
        <hr>
        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Totale
            </div>
            <div class="detail-amt font-weight-bolder">
              €{{ totalAmount - getDiscountOfPrice }}
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Totale V.P.C.
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ totalVpc }}
            </div>
          </li>
        </ul>
        <b-button
          variant="primary"
          block
          :disabled="getQuantityOfItems === 0 || (paymentMethodsLoadStatus === 2 && paymentMethods.length === 0 || paymentMethodSelected === null) || spinner"
          @click="payOrder"
        >
          <b-spinner
            v-if="spinner"
            small
          />
          Concludi Ordine
        </b-button>

      </b-card>
    </div>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
  import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BFormGroup, BFormRadio, BFormInput, BButton, BOverlay, BSpinner, BFormCheckbox,
  } from 'bootstrap-vue'
  import { useEcommerce } from '@/views/e-commerce/useEcommerce'
  import store from '@/store'
  import { ref } from '@vue/composition-api'

  export default {
    components: {
      // BSV
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BFormGroup,
      BFormRadio,
      BFormInput,
      BButton,
      BOverlay,
      BSpinner,
      BFormCheckbox,
    },
    props: {
      paymentDetails: {
        type: Object,
        required: true,
      },
    },
    computed: {
      totalAmount() {
        let total = 0
        if (this.getCurrentItemsInCart()) {
          this.getCurrentItemsInCart()
            .forEach(i => {
              total += i.price * i.quantity
            })
        }
        return total
      },
      totalVpc() {
        let total = 0
        if (this.getCurrentItemsInCart()) {
          this.getCurrentItemsInCart()
            .forEach(i => {
              total += i.vpc
            })
        }
        return total
      },
      getQuantityOfItems() {
        let total = 0
        if (this.getCurrentItemsInCart()) {
          this.getCurrentItemsInCart()
            .forEach(i => {
              total += i.quantity
            })
        }
        return total
      },
      getDiscountOfPrice() {
        if (this.paymentMethodSelected === 'contanti' || this.pickUpInSite) {
          let discount = 0
          this.getCurrentItemsInCart()
              .forEach(i => {
                if(i.type === 'caffe'){
                  discount += i.quantity * 8
                }
              })
          return discount
        }
        return 0
      }
    },
    data() {
      return {
        stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,

        stripe: '',
        elements: '',
        card: '',

        intentToken: '',
        name: '',
        addPaymentStatus: 0,
        addPaymentStatusError: '',

        paymentMethods: [],
        paymentMethodsLoadStatus: 0,
        paymentMethodSelected: null,
        pickUpInSite: null,

        show: false,
      }
    },
    mounted() {
      this.loadIntent()

      this.includeStripe('js.stripe.com/v3/', () => {
        this.configureStripe()
      })

      this.getPaymentMethods()
    },
    setup() {
      const items = ref([])
      const total = ref(0)
      const spinner = ref(false)

      const { getCurrentItemsInCart } = useEcommerce()

      const fetchCartProducts = () => {
        store.dispatch('ecommerce/fetchCartProducts')
          .then(response => {
            items.value = response.data.data.items
            total.value = response.data.data.meta.total
            store.commit('ecommerce/UPDATE_ITEMS_IN_CART', response.data.data.items)
          })
      }
      fetchCartProducts()

      return {
        fetchCartProducts,
        items,
        total,
        spinner,
        getCurrentItemsInCart,
      }
    },
    methods: {

      payOrder() {
        this.spinner = true
        if (this.paymentMethodSelected !== 'contanti' && this.paymentMethodSelected !== 'bonifico') {
          var stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
          this.$store.dispatch('ecommerce/paymentSession',{ payment_method: this.paymentMethodSelected, pickup_method: this.pickUpInSite })
            .then(response => {
              return stripe.redirectToCheckout({ sessionId: response.data.data.id })
            })
        } else {
          this.$store.dispatch('ecommerce/paymentSession',{ payment_method: this.paymentMethodSelected })
            .then(response => {
              this.$router.push({ name:'dashboard', params: { order: this.paymentMethodSelected}})
            })
        }
      },

      loadIntent() {
        this.$store.dispatch('users/setupIntent')
          .then(response => {
            this.intentToken = response.data.data
          })
      },

      submitPaymentMethod() {
        this.show = !this.show
        this.addPaymentStatus = 1
        this.stripe.confirmCardSetup(
          this.intentToken.client_secret, {
            payment_method: {
              card: this.card,
              billing_details: {
                name: this.name,
              },
            },
          },
        )
          .then(result => {
            if (result.error) {
              this.addPaymentStatus = 3
              this.addPaymentStatusError = result.error.message
            } else {
              this.savePaymentMethod(result.setupIntent.payment_method)
              this.addPaymentStatus = 2
              this.card.clear()
              this.name = ''
            }
          })
      },

      savePaymentMethod(method) {
        this.$store.dispatch('users/addPaymentMethod', { payment_method: method })
          .then(response => {
            this.show = false
            this.getPaymentMethods()
          })
      },

      getPaymentMethods(method) {
        this.paymentMethodsLoadStatus = 1
        this.$store.dispatch('users/getPaymentMethods', { payment_method: method })
          .then(response => {
            this.paymentMethods = response.data.data
            this.paymentMethodsLoadStatus = 2
          })
      },

      /*
         Includes Stripe.js dynamically
     */
      includeStripe(URL, callback) {
        const documentTag = document
        const tag = 'script'
        const object = documentTag.createElement(tag)
        const scriptTag = documentTag.getElementsByTagName(tag)[0]
        object.src = `//${URL}`
        if (callback) {
          object.addEventListener('load', e => {
            callback(null, e)
          }, false)
        }
        scriptTag.parentNode.insertBefore(object, scriptTag)
      },

      /*
      Configures Stripe by setting up the elements and
      creating the card element.
      */
      configureStripe() {
        this.stripe = Stripe(this.stripeAPIToken)

        this.elements = this.stripe.elements()
        this.card = this.elements.create('card')

        this.card.mount('#card-element')
      },

    },
  }
</script>

<style lang="scss" scoped>
    #cvv {
        width: auto;
    }
</style>
